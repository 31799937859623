<script lang="ts">
import GradientButton from '$components/Buttons/GradientButton.svelte'
import type { ANIMATED_HERO_TYPE } from '$types/index'
import Gsap from 'gsap'
import { onMount } from 'svelte'

export let animatedHeroData: ANIMATED_HERO_TYPE
const { title, description, logos } = animatedHeroData.fields

let canvasEl: HTMLCanvasElement | undefined

onMount(() => {
  if (canvasEl) {
    const ctx = canvasEl.getContext('2d')
    if (!ctx) return

    canvasEl.width = innerWidth
    canvasEl.height = innerHeight

    const wave = {
      y: canvasEl.height / 2,
      length: 0.008,
      amplitude: 240,
      frequency: -0.007,
    }

    const strokeColor = {
      r: 138,
      g: 9,
      b: 184,
      a: 1,
    }

    const backgroundColor = {
      r: 0,
      g: 0,
      b: 0,
      a: 0.05,
    }

    const tl = Gsap.timeline({ repeat: -1 }),
      timer = 7,
      timer2 = 2

    tl.delay(4)
      //to orange
      .to(
        wave,
        {
          frequency: '-0.003',
          ease: 'linear',
          duration: timer2,
          id: 'toOrange',
        },
        'toOrange',
      )
      .to(
        strokeColor,
        { r: '255', g: '86', b: '14', ease: 'power2.out', duration: timer },
        'toOrange',
      )
      .to(
        wave,
        { length: '0.006', ease: 'linear', duration: timer },
        'toOrange',
      )

      .delay(4)
      //to red
      .to(
        wave,
        { frequency: '-0.003', ease: 'linear', duration: timer2, id: 'toRed' },
        'toRed',
      )
      .to(
        strokeColor,
        { r: '255', g: '0', b: '31', ease: 'power2.out', duration: timer },
        'toRed',
      )
      .to(wave, { length: '0.004', ease: 'linear', duration: timer }, 'toRed')

      .delay(4)
      //back to orange
      .set(wave, { frequency: '-0.007', id: 'backToOrange' }, 'backToOrange')
      .to(
        strokeColor,
        { r: '255', g: '86', b: '14', ease: 'power2.out', duration: timer },
        'backToOrange',
      )
      .to(
        wave,
        { length: '0.006', ease: 'linear', duration: timer },
        'backToOrange',
      )

      .delay(4)
      //back to purple
      .set(wave, { frequency: '-0.007', id: 'backToPurple' }, 'backToPurple')
      .to(
        strokeColor,
        { r: '138', g: '9', b: '184', ease: 'power2.out', duration: timer },
        'backToPurple',
      )
      .to(
        wave,
        { length: '0.008', ease: 'linear', duration: timer },
        'backToPurple',
      )

    let increment = wave.frequency
    let lastUpdate: number

    function animate(ctx: CanvasRenderingContext2D) {
      if (canvasEl && (Date.now() - lastUpdate > 1 || !lastUpdate)) {
        lastUpdate = Date.now()
        ctx.fillStyle = `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`
        ctx.fillRect(0, 0, canvasEl.width, canvasEl.height)

        ctx.beginPath()
        ctx.moveTo(-10, canvasEl.height / 2)
        for (let i = 0; i < canvasEl.width; i++) {
          ctx.lineTo(
            i,
            wave.y +
              Math.sin(i * wave.length + increment) *
                wave.amplitude *
                Math.sin(increment),
          )
        }

        ctx.lineWidth = 4.5
        ctx.strokeStyle = `rgba(${strokeColor.r}, ${strokeColor.g}, ${strokeColor.b}, ${strokeColor.a})`
        ctx.stroke()
        increment += wave.frequency
      }

      window.requestAnimationFrame(() => animate(ctx))
    }
    window.requestAnimationFrame(() => animate(ctx))
  }
})
</script>

<section class="animated-hero">
  <div class="main-container">
    <canvas bind:this={canvasEl} height="939" width="1410" />
    <div class="gradient-container">
      <h1>{title}</h1>
    </div>
    <div class="secondary-container">
      <h4>
        {description}
      </h4>
      <GradientButton link="/platform" text="Learn More" />
    </div>
  </div>
  <div class="logo-container">
    {#each logos as logo}
      <div class="logo-wrapper">
        <img src={logo.fields.file.url} alt={logo.fields.title} />
      </div>
    {/each}
  </div>
</section>

<style lang="scss">
.animated-hero {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px 88px 25px;
  position: relative;
  overflow: hidden;

  .main-container {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    position: relative;

    .gradient-container {
      margin-bottom: 130px;
      position: relative;

      h1 {
        font-size: 50px;
        line-height: 54px;
        font-weight: 400;
        width: 100%;
        margin: 0;

        @media only screen and (min-width: 1024px) {
          font-size: 124px;
          line-height: 134px;
          width: 962px;
        }
      }
    }

    canvas {
      position: absolute;
      z-index: -1;
      top: -100px;
      width: calc(100% + 55px);
      height: 466px;
      margin-left: -30px;

      @media only screen and (min-width: 768px) {
        top: -200px;
        height: 600px;
      }

      @media only screen and (min-width: 1024px) {
        width: calc(100% + 205px);
        height: 100%;
        margin-left: -105px;
      }
    }
  }

  .secondary-container {
    width: 290px;
    margin-bottom: 48px;

    h4 {
      font-size: 17px;
      line-height: 22px;
      letter-spacing: 0.16px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 30px;

      @media only screen and (min-width: 1024px) {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 18px;
      }
    }

    @media only screen and (min-width: 1024px) {
      width: 531px;
    }

    @media only screen and (min-width: 1400px) {
      margin-left: auto;
      margin-bottom: 150px;
    }
  }

  .secondary-container :global(.gradient-button) {
    margin-right: 10px;
  }

  .logo-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
    column-gap: 10px;

    .logo-wrapper {
      text-align: center;
      width: 100%;
      height: auto;
      max-height: 18px;
      max-width: 80px;

      @media only screen and (min-width: 1024px) {
        max-height: 30px;
        max-width: 140px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: 1400px) {
      display: flex;
      justify-content: space-between;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 0 100px 88px 100px;
  }
}
</style>
