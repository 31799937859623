<script lang="ts">
export let text: string
</script>

<h3 class="section-subtitle">{text}</h3>

<style lang="scss">
h3 {
  font-size: 40px;
  line-height: 43px;
  font-weight: 400;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 25px;

  @media only screen and (min-width: 1280px) {
    font-size: 60px;
    line-height: 101px;
    font-weight: 500;
    margin-bottom: 30px;
    max-width: 85%;
  }
}
</style>
