<script lang="ts">
export let link = ''
export let text = ''
</script>

<a href={link} class="border-gradient-button">
  <span>
    {text}
  </span>
</a>

<style lang="scss">
a {
  display: inline-block;
  width: 196px;
  height: 36px;
  margin: 0 auto;
  border: 0;
  padding: 0;
  position: relative;
  color: var(--white);
  background-image: linear-gradient(
    to right,
    #ff560e 25%,
    #ff001f 50%,
    #8802c8 75%,
    #6202fe 100%
  );
  border-radius: 29px;
  box-sizing: border-box;

  span {
    width: 192px;
    height: 32px;
    border-radius: 29px;
    top: 2px;
    left: 2px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.32px;
    background-color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    position: relative;
  }

  span:hover {
    color: var(--hoverOrange);
  }
  @media only screen and (min-width: 1024px) {
    width: 255px;
    height: 49px;

    span {
      width: 251px;
      height: 45px;
      font-size: 18px;
      line-height: 21px;
    }
  }
}

/* a:hover {
    animation: bgp 1s linear infinite;
  } */

@keyframes bgp {
  to {
    background-position: -500px 0;
  }
}
</style>
