<script lang="ts">
import HomepageSectionSubtitle from '$components/Typography/HomepageSectionSubtitle.svelte'
import SectionTitle from '$components/Typography/SectionTitle.svelte'
import type { PRODUCT_EMBED_CONTAINER_TYPE } from '$types/index'

export let productEmbedData: PRODUCT_EMBED_CONTAINER_TYPE

const { sectionTitle, sectionSubtitle } = productEmbedData.fields
</script>

<svelte:head>
  <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
</svelte:head>

<section class="embed-container">
  <SectionTitle text={sectionTitle} />
  <HomepageSectionSubtitle text={sectionSubtitle} />
  <div class="main-container">
    <div class="embed-carousel">
      <div class="video-wrapper">
        <mux-player
          stream-type="on-demand"
          playback-id="8DoRZyib5qfqfLvm1PB1FtcG2B3EmCnm"
          metadata-video-title="Lumiere, Explained"
          poster="https://img.lumiere.is/unsecure/crop:0:0:no/rs:fit:1280:720:true/g:no/plain/gs%3A%2F%2Flumiere-v4-prod.appspot.com%2Fteams%2F4VkvuZaHIQnR%2Fvideos%2FvbQN4qtIv3Kt%2Fthumbnail%2Fresized_default.webp"
          primary-color="#FFFFFF"
          secondary-color="#000000">
        </mux-player>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
.embed-container {
  height: auto;
  position: relative;
  .main-container {
    padding: 0 20px 20px 30px;
    .embed-carousel {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      border-radius: 16px;
      .video-wrapper {
        margin-right: 17px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        mux-player {
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      padding: 0 40px 40px 100px;
    }
  }

  :global(.section-title) {
    padding-left: 30px;

    @media only screen and (min-width: 1024px) {
      padding-left: 100px;
    }
  }

  :global(.section-subtitle) {
    padding-left: 30px;

    @media only screen and (min-width: 1024px) {
      padding-left: 100px;
    }
  }
}
</style>
