<script lang="ts">
import BorderGradientButton from '$components/Buttons/BorderGradientButton.svelte'
import HomepageSectionSubtitle from '$components/Typography/HomepageSectionSubtitle.svelte'
import Network from '$components/Typography/Network.svelte'
import SectionTitle from '$components/Typography/SectionTitle.svelte'
import Tag from '$components/Typography/Tag.svelte'
import type { CASE_STUDY_CAROUSEL_TYPE } from '$types/index'

export let caseStudiesData: CASE_STUDY_CAROUSEL_TYPE

const { sectionTitle, sectionSubtitle, caseStudies } = caseStudiesData.fields
$: item = caseStudies[0]
</script>

<section class="case-study-container">
  <SectionTitle text={sectionTitle} />
  <HomepageSectionSubtitle text={sectionSubtitle} />

  <div class="main-container">
    <div class="case-study-carousel">
      <div class="case-study">
        <a
          class="case-study-link"
          href="/case-studies/{item.fields.id}"
          style="background-image: url('{item.fields.imageSrcFull.fields.file
            .url}')">
          <div class="tag-wrapper">
            <Tag tag={item.fields.tag} />
          </div>
          <div class="content-wrapper">
            <h4 class="title">{item.fields.headline}</h4>
            <Network network={item.fields.network} />
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="button-wrapper">
    <BorderGradientButton link="/case-studies" text="View All Case Studies" />
  </div>
</section>

<style lang="scss">
.case-study-container {
  height: auto;
  margin-top: 20px;
  position: relative;

  .main-container {
    margin: 0 30px 65px 30px;

    @media only screen and (min-width: 1024px) {
      margin: 0 40px 65px 100px;
    }
  }

  :global(.section-title) {
    margin-left: 30px;
    @media only screen and (min-width: 1024px) {
      margin-left: 100px;
    }
  }

  :global(.section-subtitle) {
    padding-left: 30px;

    @media only screen and (min-width: 1024px) {
      padding-left: 100px;
    }
  }

  .button-wrapper {
    margin: 0 auto;
    text-align: center;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
  }
}

.case-study-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 0;
  padding-bottom: 66.7%;
  position: relative;
  .case-study {
    margin-right: 35px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .case-study-link {
      width: 100%;
      height: 100%;
      display: inline-block;
      background-size: cover;
      border-radius: 16px;
      position: relative;

      &:hover {
        color: inherit;

        .title {
          color: var(--hoverOrange);
          transition: all 0.2s ease;
        }
      }

      &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 70%,
          rgba(0, 0, 0, 0.65) 100%
        );
      }

      .tag-wrapper {
        position: absolute;
        top: 14px;
        left: 12px;
        display: flex;

        @media only screen and (min-width: 1024px) {
          top: 40px;
          left: 40px;
        }
      }
    }
  }
}

.content-wrapper {
  position: absolute;
  bottom: 15px;
  left: 12px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 0.32px;
    font-weight: 400;
    margin-bottom: 14px;
    width: 80%;

    @media only screen and (min-width: 1024px) {
      font-size: 52px;
      line-height: 61px;
      margin-bottom: 20px;
      width: 70%;
    }
  }

  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 50px;
    left: 40px;
    display: flex;
    flex-direction: column;
  }
}
</style>
